












import Vue from 'vue';
import ProductItem from '@/components/product/product-item.vue';
import { TRANSPORT, WORK, ELEKTRONIK, WINDENERGIEANLAGEN, BIOGAS, ASSEMBLY, PHOTOVOLTAIK_LOGO } from '@/config/images';
import { productNames,
  ASSEMBLY_INSURANCE_KEY,
  BIOGAS_INSURANCE_KEY,
  CONSTRUCTION_WORK_INSURANCE_KEY,
  ELEKTRONIK_INSURANCE_KEY,
  INDIVIDUAL_INSURANCE_KEY,
  PHOTOVOLTAIK_INSURANCE,
  WINDENERGIEANLAGEN_INSURANCE_KEY } from '@/constant/constants';
import priceCalculator from '../service/api-insurance';
// import {WEBSITE_URL} from "@/constant/constants";

export default Vue.extend({
  name: 'HomeMain',
  components: {
    ProductItem,
  },
  data: () => ({
    products: [] as any,
  }),
  mounted() {
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
    this.getInsuranceForUser();
    if (this.IDInsurance) {
      const path = `get-insurance/${this.IDInsurance}`;
      this.$router.push({
        path,
      }).catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    }
  },
  methods: {
    getInsuranceForUser() {
      let instanceArray = {
      };
      priceCalculator.getProducts().then((response) => {
        if (response.data.find((x: any) => x.name === INDIVIDUAL_INSURANCE_KEY)) {
          instanceArray = {
            id: 1,
            name: productNames[INDIVIDUAL_INSURANCE_KEY],
            description: 'Esa TV Individual',
            image: TRANSPORT,
            link: '/individual-insurance',
            key: INDIVIDUAL_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === CONSTRUCTION_WORK_INSURANCE_KEY)) {
          instanceArray = {
            id: 2,
            name: productNames[CONSTRUCTION_WORK_INSURANCE_KEY],
            description: 'Esa Bauleistung',
            image: WORK,
            link: '/bauleistung-insurance',
            key: CONSTRUCTION_WORK_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === ELEKTRONIK_INSURANCE_KEY)) {
          instanceArray = {
            id: 3,
            name: productNames[ELEKTRONIK_INSURANCE_KEY],
            description: 'Esa Elektronik',
            image: ELEKTRONIK,
            link: '/elektronik-insurance',
            key: ELEKTRONIK_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === WINDENERGIEANLAGEN_INSURANCE_KEY)) {
          instanceArray = {
            id: 4,
            name: productNames[WINDENERGIEANLAGEN_INSURANCE_KEY],
            description: 'Windenergieanlagen',
            image: WINDENERGIEANLAGEN,
            link: '/windenergieanlagen-insurance',
            key: WINDENERGIEANLAGEN_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === BIOGAS_INSURANCE_KEY)) {
          instanceArray = {
            id: 5,
            name: productNames[BIOGAS_INSURANCE_KEY],
            description: 'Biogas ',
            image: BIOGAS,
            link: '/biogas-insurance',
            key: BIOGAS_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === ASSEMBLY_INSURANCE_KEY)) {
          instanceArray = {
            id: 6,
            name: productNames[ASSEMBLY_INSURANCE_KEY],
            description: 'Montageversicherung ',
            image: ASSEMBLY,
            link: '/assembly-insurance',
            key: ASSEMBLY_INSURANCE_KEY,
          };
          this.products.push(instanceArray);
        }
        if (response.data.find((x: any) => x.name === PHOTOVOLTAIK_INSURANCE)) {
          instanceArray = {
            id: 7,
            name: productNames[PHOTOVOLTAIK_INSURANCE],
            description: 'Photovoltaik ',
            image: PHOTOVOLTAIK_LOGO,
            link: '/photovoltaics-insurance',
            key: PHOTOVOLTAIK_INSURANCE,
          };

          this.products.push(instanceArray);
        }
      });
    },
    getSIDFromStore(): string | null{
      return this.$store.getters['sidModule/getSID'];
    },
    getIDFromStore(): number | null{
      return this.$store.getters['sidModule/getInsuranceId'];
    },
  },
  computed: {
    SID(): string | null {
      return this.getSIDFromStore();
    },
    IDInsurance(): number | null {
      return this.getIDFromStore();
    },
  },
});
